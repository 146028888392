import "../App.css";

import "../CSS/Pages/ProjectsInfo.css";

function ProjectsInfo() {
  return (
    <div className="main-container">
      <div className="projects-container">
        <h1 className="projects-container">
         Below are some of the projects I developed during and after my graduation in IT. These projects were built using Python, Machine Learning, React, React Native, JavaScript, CSS, HTML, and PHP.{" "}
        </h1>
      </div>
      <div className="cards">
        <div className="card">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            {" "}
            The application was developed in React Native and JavaScript with Register users in database (SQLite),
            Authenticate users, find users by id, update/delete user. The
            app was designed to be user-friendly, with easy access
            buttons and fewer steps to register users. On the home screen, it is
            possible to access all functions the application offers to users.
          </h2>
          <p className="card_apply">
            <a
            
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/SQLiteProject"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            This application was created to support the users during a
            collective trip to split the amount spent. The system has a Java
            backend and a REACT Native mobile application front end. The
            application allows people to create a label, name of the trip, and
            store information referring to the trip, that is, all the expenses
            that each person had during the trip. It is possible to consult all
            costs at any time of the trip and close the trip. 
            Then, in the end, all expenses will be divided equally among the
            members of the trip.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/SplitEasily"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            The "Ger Garage "Mobile Application was designed based on the need
            to implement a system to organise and manage booking for a small
            garage, the "Ger Garage" Mobile Application was designed. This
            application solves a real problem for small businesses regarding
            booking allocation while helping to improve customer service,
            maximising repair time, and streamlining work. 
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/gerGarage"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            The app will offer a real time news feed and it will allow users the
            possibility to have access to events as: Business news, Financial
            news andSports news. All the headline of news will be displayed in a
            Graphic User Interface and also the time it has being published. The
            news will be available to the user and it is possible to save the
            information and check it later. Also, the users can manager their
            historic and delete and add information. The application will use an
            external API (https://newsapi.org/) to collect the news and show it
            to the users. .
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/python"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            Simple forms in PHP to allow users to contact by email.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              target="_blank"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/formsPHP"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
        <div className="card">
          <div className="card_icon">
            <i className="fab fa-github"></i>
          </div>
          <p className="card_exit">
            <i className="fas fa-times"></i>
          </p>
          <h2 className="card_content">
            Website developed with JavaScript, CSS, NEXT.js, Node.js.
          </h2>
          <p className="card_apply">
            <a
              className="card_link"
              rel="noreferrer"
              href="https://github.com/derekbomfimprates/react-derek-website"
            >
              See code <i className="fas fa-arrow-right"></i>
            </a>
          </p>
        </div>
                <div className="card">
   <div className="card_icon">
      <i className="fab fa-github"></i>
   </div>
   <p className="card_exit">
      <i className="fas fa-times"></i>
   </p>
   <h2 className="card_content">
      This project analyzes the importance of preprocessing steps in Car Insurance Claim Prediction using Python and machine learning algorithms. It explores various models, comparing performance metrics like accuracy and precision with and without preprocessing.
   </h2>
   <p className="card_apply">
      <a
         className="card_link"
         target="_blank"
         rel="noreferrer"
         href="https://github.com/derekbomfimprates/CarInsuranceClaimPrediction"
      >
         See code <i className="fas fa-arrow-right"></i>
      </a>
   </p>
</div>

<div className="card">
   <div className="card_icon">
      <i className="fab fa-github"></i>
   </div>
   <p className="card_exit">
      <i className="fas fa-times"></i>
   </p>
   <h2 className="card_content">
      This project applies two image classification models: a Convolutional Neural Network (CNN) from scratch and a pre-trained Transfer Learning model. The comparison addresses class imbalance, fine-tuning, and overall performance.
   </h2>
   <p className="card_apply">
      <a
         className="card_link"
         target="_blank"
         rel="noreferrer"
         href="https://github.com/derekbomfimprates/ConvolutionalNeuralNetworkAndTransferLearning"
      >
         See code <i className="fas fa-arrow-right"></i>
      </a>
   </p>
</div>

<div className="card">
   <div className="card_icon">
      <i className="fab fa-github"></i>
   </div>
   <p className="card_exit">
      <i className="fas fa-times"></i>
   </p>
   <h2 className="card_content">
      This Python project uses machine learning algorithms to detect fraud, employing model training and testing on various datasets to identify patterns indicative of fraudulent activity.
   </h2>
   <p className="card_apply">
      <a
         className="card_link"
         target="_blank"
         rel="noreferrer"
         href="https://github.com/derekbomfimprates/FraudDetection"
      >
         See code <i className="fas fa-arrow-right"></i>
      </a>
   </p>
</div>

<div className="card">
   <div className="card_icon">
      <i className="fab fa-github"></i>
   </div>
   <p className="card_exit">
      <i className="fas fa-times"></i>
   </p>
   <h2 className="card_content">
      The Olympic History Dashboard provides an interactive analysis of athlete participation, medals, and performance in the Olympic Games. The dashboard utilizes design principles to create a clear, user-friendly view of data trends over time.
   </h2>
   <p className="card_apply">
      <a
         className="card_link"
         target="_blank"
         rel="noreferrer"
         href="https://github.com/derekbomfimprates/Tableau-Dashboard-"
      >
         See code <i className="fas fa-arrow-right"></i>
      </a>
   </p>
</div>
           <div className="card">
   <div className="card_icon">
      <i className="fab fa-github"></i>
   </div>
   <p className="card_exit">
      <i className="fas fa-times"></i>
   </p>
   <h2 className="card_content">
      Welcome to the MyCCangel.org website repository! This project aims to create a comprehensive website using the Ionic framework, combining advanced data analysis with an AI-powered advice tool. The AI tool utilizes a large language model (LLM) to provide personalized, intelligent insights, making it easier for users to receive tailored advice and support.

Key Features:

Ionic Framework: Ensures a responsive, cross-platform experience for both web and mobile users.
Data Analysis Tools: Leverages user data to generate actionable insights and valuable advice.
AI Tool: Powered by a large language model, this tool provides smart, context-aware recommendations based on user inputs, empowering users with advice relevant to their unique needs.
Our mission is to use technology to guide users with impactful advice, fostering a supportive community at MyCCangel.org.
   </h2>
   <p className="card_apply">
      <a
         className="card_link"
         target="_blank"
         rel="noreferrer"
         href="https://github.com/derekbomfimprates/myccangel/tree/master"
      >
         See code <i className="fas fa-arrow-right"></i>
      </a>
   </p>
</div>
           <div className="card">
   <div className="card_icon">
      <i className="fab fa-github"></i>
   </div>
   <p className="card_exit">
      <i className="fas fa-times"></i>
   </p>
   <h2 className="card_content">
      The main objective of this project is to identify and handle outliers in a dataset to improve data quality for analysis.
   </h2>
   <p className="card_apply">
      <a
         className="card_link"
         target="_blank"
         rel="noreferrer"
         href="https://github.com/derekbomfimprates/OUTLIER_DETECTION"
      >
         See code <i className="fas fa-arrow-right"></i>
      </a>
   </p>
</div>

      </div>
    </div>
  );
}

export default ProjectsInfo;
