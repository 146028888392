import React from 'react';

import Footer from '../components/Footer';

import ProjectsInfo from '../components/ProjectsInfo';
import ProjectsTop from '../components/ProjectsTop';


function Projects(){
    return(
    
        <>
        <ProjectsTop />
        <ProjectsInfo />
       
        <Footer />
        </>
    );
}


export default Projects;