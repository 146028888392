import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

import '../CSS/Components/TopCv.css';


function TopCv() {
    return (
        <div className='top-container-cv'>
         
          <h1>Resume</h1>
           <div className='top-container-resume'>

          <Link
                  to="/files/resume.pdf"
                  className="botton-download"
                  target="_blank"
                  download
               >
                 <i className="fas fa-file-pdf"></i>
                  Download PDF Version
               </Link>
               </div>
    
       </div>
      );
    }
    
    export default TopCv;