import React from 'react';

import ContactInfo from '../components/ContactInfo';
import ContactTop from '../components/ContactTop';
import Footer from '../components/Footer';





export default function Contact() {
   return(
    
    <>
  
    <ContactTop />
    <ContactInfo />
    <Footer />
    
    </>
);
}