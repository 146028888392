import React from 'react';
import '../App.css';

import '../CSS/Pages/ContactTop.css';

function ContactTop() {
    return (
        <div className='top-container-contact'>
          <h1>Contact</h1>
    
        </div>
      );
    }
    
export default ContactTop;