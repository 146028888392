import React from 'react';
import '../CSS/Components/Footer.css';

import { Link } from 'react-router-dom';


function Footer() {
  return (
    <div className='footer-container'>
      <h1>MORE INFORMATION:</h1>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Me</h2>
            <Link to='/cv'>My Cv</Link>
            <Link to='/contact'>Hire me</Link>
            <Link to='/projects'>Projects</Link>
          
          </div>
          <div className='footer-link-items'>
            <h2>Contact </h2>
            <Link to='/contact'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a target='_blank' rel="noreferrer" href='https://github.com/derekbomfimprates'>GitHub</a>
            <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/derekprates'>Linkedin</a>
            <a target='_blank' rel="noreferrer" href='https://www.researchgate.net/profile/Derek-Prates'>ResearchGate</a>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              DEREK
              <i className="fab fa-react" />
            </Link>
         </div>
          <small className='website-rights'>Derek © 2021</small>
          <div className='social-icons'>
            <a  rel="noreferrer"
              className='social-icon-link github'
              a href='https://github.com/derekbomfimprates'
              target='_blank'
              aria-label='GitHub'
            >
              <i className="fab fa-github" />
            </a>
            <a rel="noreferrer" 
              className='social-icon-link linkedin'
              href='https://www.linkedin.com/in/derekprates'
              target='_blank'
              aria-label='Linkedin'
            >
              <i className="fab fa-linkedin"/>
            </a>
            <a rel="noreferrer" 
              className='social-icon-link researchgate'
              href='https://www.researchgate.net/profile/Derek-Prates'
              target='_blank'
              aria-label='Researchgate'
            >
              <i className="fab fa-researchgate"/>
            </a>
            
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;