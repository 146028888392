import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages/Home'
import Projects from './pages/Projects';
import Cv from './pages/Cv';
import Contact from './pages/Contact';



function App() {
  return (
    
  
   <Router>
     <Navbar/>
     <Switch>
       <Route path='/' exact component={Home}/>
       <Route path='/projects' component={Projects} />
       <Route path='/cv' component={Cv} />
       <Route path='/contact' component={Contact} />
     
     </Switch>
   </Router>
      
      
  );
}

export default App;

