import "../App.css";
import "../CSS/Pages/CvInfor.css";

function CvInfor() {
  return (
    <div className="infor-container">
      <h1>Derek Bomfim Prates</h1>

      <h3>PROFESSIONAL SUMMARY:</h3>
      <p>
        Highly motivated and detail-oriented data analytics professional,
        currently pursuing a Master’s degree in Data Analytics. I bring a strong
        foundation in engineering, computing, and data analysis with over 3
        years of experience in IT support and data-driven decision-making. I am
        passionate about leveraging data to drive impactful solutions and
        support business decision-making.
      </p>
      <p>
        I am developing an AI-driven tool to assist individuals with
        Inflammatory Bowel Disease (IBD), utilizing machine learning and data
        mining techniques. Proficient in Python, Power BI, SQL, and Excel, I
        have a proven track record in building dashboards, performing data
        validation, and creating actionable insights. Seeking to apply my
        technical skills and analytical mindset in a dynamic data analytics
        role.
      </p>

      <h3>KEY SKILLS:</h3>
      <p>
        <strong> • Data Reporting & Analysis:</strong> Expertise in creating
        interactive dashboards and visualizations using Power BI, Python, and
        SQL to drive data-based decision-making.
      </p>
      <p>
        <strong> • Data Management:</strong> Ensured data integrity, accuracy,
        and consistency through comprehensive data validation, cleansing, and
        transformation processes.
      </p>
      <p>
        <strong> • Business Intelligence:</strong> Skilled in data mining, data
        warehousing, and applying analytics tools to generate detailed
        business insights and optimize performance.
      </p>
      <p>
        <strong> • Machine Learning & AI:</strong> Applied machine learning
        techniques and developed a chatbot using large language models to mine
        data for healthcare solutions.
      </p>
      <p>
        <strong> • Programming:</strong> Proficient in Python, SQL, JavaScript,
        and C++ for data manipulation, automation, and analytics.
      </p>
      <p>
        <strong> • Project Management:</strong> Successfully led and managed
        data-driven projects using Agile methodologies, ensuring timely and
        high-quality deliverables.
      </p>

      <h3>EDUCATION:</h3>
      <h4>Master of Science in Data Analytics | 2023 - 2025</h4>
      <p>Dublin Business School | Dublin, Ireland</p>

      <h4>Honours Higher Diploma in Science in Computing | 2020 - 2021</h4>
      <p>CCT College | Dublin, Ireland</p>

      <h4>Bachelor’s Degree in Industrial Engineering | 2012 - 2017</h4>
      <p>UFVJM | Minas Gerais, Brazil</p>

      <h4>Bachelor’s Degree in Science and Technology | 2012 - 2015</h4>
      <p>UFVJM | Minas Gerais, Brazil</p>

      <h3>WORK EXPERIENCE:</h3>

      <h4>Deskside Support Engineer Level 2 | 11/2022 - Current</h4>
      <p>FDS/DXC | Leixlip, Ireland</p>
      <p>
        • Conducted data analysis to optimize troubleshooting and improve
        technical issue resolution by 15%. <br />
        • Developed and maintained IT infrastructure, improving uptime and
        efficiency for Intel Corporation. <br />
        • Created and documented 7 Knowledge Base articles, enhancing team
        efficiency in resolving technical issues by 20%. <br />
        • Provided advanced technical support and data validation, ensuring
        accuracy and integrity of system configurations.
      </p>
      <p>
        <strong>Achievements:</strong> <br />
        o Created comprehensive troubleshooting guides to enhance the team's
        efficiency in resolving technical issues. <br />
        o Designed and implemented 7 Knowledge Base articles that optimized
        support for audiovisual and software systems, improving team
        performance. <br />
        o Provided advanced technical training to new team members, improving
        onboarding and technical skills development.
      </p>

      <h4>Deskside Support Engineer Level 1 | 12/2021 - 11/2022</h4>
      <p>FDS/DXC | Leixlip, Ireland</p>
      <p>
        ● Provided 1st and 2nd-level desktop/laptop support. <br />
        ● Supported Virtualization technology (VMWare, Microsoft Azure, etc.).
        <br />
        ● Supported Network administration, Firewall Configuration, and strong
        knowledge of TCP/IP. <br />
        ● Supported machines through programming tools and test software. <br />
        ● Provided support for Walk-in Service Centre, Installs, Breakfix, and
        Refresh. <br />
        ● Supported Warehouse, Remote Sites, Home Users, and Digital Signage.
        <br />
        ● Controlled users and computers through Active Directory (ADDS, DHCP,
        DATABASE). <br />
        ● Troubleshot Office Software Issues and supported Office Suite. <br />
        ● Supported Wireless office environment.
      </p>
      <p>
        <strong>Achievements:</strong> <br />
        ○ Due to outstanding performance and demonstrated knowledge, promoted
        to Deskside Support Engineer Level 2.
      </p>

      <h4>Store Manager (Part-time) | 12/2019 - 12/2021</h4>
      <p>Scoop | Dublin, Ireland</p>
      <p>
        ● Provided excellent customer service, focused on customer problem
        identification and resolution. <br />
        ● Maintained high standards for employee service and knowledge. <br />
        ● Hired, trained, supervised, and developed employees. <br />
        ● Managed employee schedules and monitored labor costs. <br />
        ● Responsible for daily and monthly administrative reports. <br />
        ● Ensured compliance with health and safety legislation.
      </p>
      <p>
        <strong>Achievements:</strong> <br />
        ○ Implemented a store-opening checklist that reduced employee mistakes
        and improved readiness for peak hours. <br />
        ○ Developed a freezer defrost calendar, reducing maintenance activities
        and allowing continuous operations on non-busy days.
      </p>

      <h4>Internship Data Analytics | 12/2019 - 12/2021</h4>
      <p>INSS | Minas Gerais, Brazil</p>
      <p>
        ● Analyzed queue performance and service flow across multiple agencies,
        leading to a 25% reduction in wait times through optimization
        strategies. <br />
        ● Developed custom data dashboards in Power BI, enabling real-time
        decision-making for service managers. <br />
        ● Applied predictive models to enhance customer flow, improving service
        efficiency across locations. <br />
        ● Presented insights and recommendations through data visualizations
        and reports to agency leaders.
      </p>
      <p>
        <strong>Achievements:</strong> <br />
        ○ Implemented queue optimization strategies that significantly reduced
        wait times and improved service efficiency. <br />
        ○ Developed custom data dashboards for real-time decision-making.
      </p>

      <h3>PROJECTS & RESEARCH:</h3>
      <h4>AI Tool Development for Inflammatory Bowel Disease (IBD)</h4>
      <p>
        Developed a chatbot leveraging large language models and data mining to
        provide users with real-time insights and support for managing IBD. <br />
        Utilized machine learning techniques to enhance chatbot responsiveness,
        creating a more personalized user experience. <br />
        Conducted data analysis on user-contributed insights to improve the
        tool’s performance, with potential for broader healthcare applications.
      </p>

      <h3>GENERAL SKILLS:</h3>
      <p>
        • Organized & Proactive: Strong organizational skills with a proactive
        approach to problem-solving.
      </p>
      <p>
        • Effective Communicator: Excellent verbal and written communication
        skills.
      </p>
      <p>
        • Team Player: Fosters a collaborative work environment to support team
        success.
      </p>

      <h3>LANGUAGES:</h3>
      <p>Portuguese: Native Language</p>
      <p>English: Fluent (Spoken, Written, and Reading)</p>
    </div>
  );
}

export default CvInfor;
