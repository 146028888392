import React from 'react';
import '../App.css';

import '../CSS/Pages/ProjectsTop.css';

function ProjectsTop() {
    return (
        <div className='top-container-projects'>
          <h1>Projects</h1>
    
        </div>
      );
    }
    
export default ProjectsTop;