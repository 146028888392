import React, { useState } from "react";

import { useForm } from "react-hook-form";
import "../App.css";
// import Recaptcha from "react-google-recaptcha";
import "../CSS/Pages/ContactInfo.scss";


// const RECAPTCHA_KEY = "6LdtPd4cAAAAAPocjBVFFcVsgtzRpnLJiIyepQGS";
export default function ContactInfo() {
//   const recaptchaRef = React.createRef(); // new Ref for reCaptcha
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
//   const [buttonDisabled, setButtonDisabled] = React.useState(true);
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const { handleSubmit, register } = useForm();

  const onSubmit = (values, e) => {
    e.preventDefault();
   //  const recaptchaValue = recaptchaRef.current.getValue();
   //  console.log(values);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
      //   "g-recaptcha-response": recaptchaValue,
        name,
        email,
        message,
      }),
    })
      .then(() => alert("Form successfully submitted"))
      .catch((error) => alert(error));
  };

  return (
    <div className="container">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form"
        name="contact"
        method="POST"
        data-netlify="true"
      //   data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="contact" />

        <div className="form__container">
          <h2 className="h2">Contact form</h2>
          <div className="form__row">
            <div className="form__input-container">
              <label htmlFor="#">Full Name</label>
              <input
                type="text"
                className="form__input"
                placeholder="Name"
                name="name"
                {...register("name", { required: true })}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form__input-container">
              <label htmlFor="#">Email</label>
              <input
                type="email"
                className="form__input"
                placeholder="Email"
                name="email"
                {...register("email", { required: true })}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form__input-container">
              <label htmlFor="#">Message</label>
              <textarea
                className="form__input"
                placeholder="Enter your message"
                cols={30}
                rows={10}
                name="message"
                {...register("message", { required: true })}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <small className="form__required">* Required fields</small>
          </div>
          {/* <Recaptcha
           
            ref={recaptchaRef}
            sitekey={RECAPTCHA_KEY}
            size="normal"
            id="recaptcha-google"
            onChange={() => setButtonDisabled(false)} // disable the disabled button!
          /> */}
          <button className="btn" type="submit" >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}
