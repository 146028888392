import React from 'react'



import Footer from '../components/Footer';

import Cards from '../components/Cards';
import InitialSection from '../components/InitialSection';
import Skills from '../components/Skills';

function Home(){
    return(
        <>
        <InitialSection />
        <Skills />
        <Cards />
        <Footer/>
        </>
    );
}

export default Home;