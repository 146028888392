import React from 'react';


import CvInfor from '../components/CvInfor';
import Footer from '../components/Footer';
import TopCv from '../components/TopCv';


function Cv(){
    return(
    
        <>
        <TopCv />
        <CvInfor />
        <Footer />
        </>
    );
}


export default Cv;