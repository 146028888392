import React from "react";
import "../CSS/Components/Cards.css";
import CardItem from "./CardItem.js";

import img1 from "../assets/images/img-2.png";
import img2 from "../assets/images/img-3.png";
import img3 from "../assets/images/img-4.png";
import img4 from "../assets/images/img-5.png";
import img5 from "../assets/images/img-6.png";
import img6 from "../assets/images/img-7.png";

function Cards() {
  return (
    <div className="cards">
      <h1>CHECK OUT MY PROJECTS</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={img6}
              text="SQLight with database"
              label="SQL - DATABASE - REACT- REACT NATIVE"
              path="/projects"
            ></CardItem>

            <CardItem
              src={img1}
              text="Split Easily - App to manage cust during a trip"
              label="REACT - REACT NATIVE - API - TYPESCRIPT "
              path="/projects"
            />
            <CardItem
              src={img2}
              text="Ger Garage - App to manage the booking system of a small company"
              label="REACT - REACT NATIVE - NEST.JS - DATABASE"
              path="/projects"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={img4}
              text="WEB SITE"
              label="HTML - CSS - NEXT.JS - EXPRESS - JAVASCRIPT"
              path="/projects"
            />
            <CardItem
              src={img3}
              text="PYTHON PROJECT"
              label="PYTHON - GUI - EXTERNAL API"
              path="/projects"
            />
            <CardItem
              src={img5}
              text="CONTACT FORMS"
              label="REACT - PHP - HTML"
              path="/projects"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
