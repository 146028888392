import React from "react";
import "../App.css";
import { Button, ButtonProjects } from "./Button";
import "../CSS/Pages/InitialSection.css";

function InitialSection() {
  return (
    <div className="initial-container">
      <img src="/images/img-1.jpg" alt="" />
      <h1>Derek Prates</h1>
      <p>
        Highly motivated and detail-oriented data analytics professional,
        currently pursuing a Master’s degree in Data Analytics. I bring a strong
        foundation in engineering, computing, and data analysis with over 3
        years of experience in IT support and data-driven decision-making. I am
        passionate about leveraging data to drive impactful solutions and
        support business decision-making.
      </p>
      <p>
        I am developing an AI-driven tool to assist individuals with
        Inflammatory Bowel Disease (IBD), utilizing machine learning and data
        mining techniques. Proficient in Python, Power BI, SQL, and Excel, I
        have a proven track record in building dashboards, performing data
        validation, and creating actionable insights. Seeking to apply my
        technical skills and analytical mindset in a dynamic data analytics
        role.
      </p>
      <div className="initial-btns">
        <Button
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          HIRE ME
        </Button>

        <ButtonProjects
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          PROJECTS
        </ButtonProjects>
      </div>
    </div>
  );
}

export default InitialSection;
